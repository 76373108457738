:root {
  --primary: #002c6b !important;
  /* --primary:#16be3a; */
  --primary-two: #fabd00;
  --primary-three: #fcfcfc;
  --text: #fabd00;
  --active-list: #002c6b;
  --danger-alt: #ff6b72;
}

.file_upload_sec {
  border: 1px dashed gray;
  height: 150px;
  cursor: pointer;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right {
  width: 50px;
  height: 50px;
  display: flex;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right {
  filter: grayscale(1);
  opacity: 0.6;
}

.file_upload_sec .img_icon_left {
  right: -50px;
  transition: all 0.3s ease;
}

.file_upload_sec .img_icon_right {
  left: -50px;
  transition: all 0.3s ease;
}

.file_upload_sec:hover .img_icon_left {
  right: -20px;
  transform: rotate(-20deg);
  transition: all 0.3s ease;
}

.file_upload_sec:hover .img_icon_right {
  left: -20px;
  transform: rotate(20deg);
  transition: all 0.3s ease;
}

.file_upload_sec .img_icon {
  z-index: 1;
}

.file_upload_sec .img_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.file_upload_sec .uploaded_img {
  object-fit: cover;
  object-position: center;
}

.file_upload_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.img_delete_sec {
  top: 0;
  left: 0;
  background-color: rgba(174, 173, 173, 0.41);
}

.delete_btn {
  border: 1px solid #ff6b72;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff6b72;
  transition: all 0.3s ease;
}

.delete_btn:hover {
  scale: 1.2;
  transition: all 0.3s ease;
}

.bg-image {
  background-color: red;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

/* .ant-table-nested-thead th{background-color: #72849a!important; color: #f0eaeaf0 !important;} */
/* .ant-table-thead th{background-color: #21864b!important; color: #f0eaeaf0 !important;} */
/* ----delete popup---- */
.delete_popup {
  background-color: rgba(0, 0, 0, 0.255);
  z-index: 1000;
}

.delete_popup .popup {
  border-radius: 5px;
  width: 300px;
}

.delete_popup .popup .dialogue {
  line-height: 20px;
}

.delete_popup .popup .del_btn {
  background-color: var(--danger-alt);
}

.delete_popup .popup .del_btn:focus-visible {
  outline: 4px solid #ff848ab1;
}

.delete_popup .popup .del_btn:hover {
  border: 1px solid #ff757c !important;
}

.show {
  display: flex;
}

.hide {
  visibility: hidden !important;
  opacity: 0;
  transition: all 0.3s ease;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.custom-input:focus {
  border-color: #efea9a;
  /* Replace with your desired color */
}

.my-custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.my-custom-scrollbar::-webkit-scrollbar-track {
  background: #f9c1c1;
}

.my-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #04340c;
}

.my-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #031201;
}

.text-editor-sec {
  scroll-behavior: smooth;
}

.text-editor-sec::-webkit-scrollbar,
.text-editor-sec *::-webkit-scrollbar {
  width: 6px;
  height: 0;
  background-color: transparent;
  margin-left: 4px;
}

.text-editor-sec::-webkit-scrollbar-thumb,
.text-editor-sec *::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: #ef233c;
  border-radius: 5px;
}

.text-editor-sec::-webkit-scrollbar-corner,
.text-editor-sec *::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

.text-editor-sec .ce-block__content {
  background-color: #feebed;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 0 8px;
}

.ce-toolbar__plus {
  background-color: #ef233c;
  color: white;
  margin-right: 3px;
}

.ce-toolbar__plus:hover {
  background-color: #fbc9cf;
  color: #ef233c;
  border: 1px solid #ef233c;
}

.cdx-search-field.ce-popover__search {
  background-color: #fbc9cf81;
}

.cdx-search-field__input {
  border: 1px solid #ef233c;
  padding: 3px;
  border-radius: 3px;
}

.cdx-search-field__input:focus {
  --tw-ring-color: #ef233c;
  border-color: #ef233bbc;
}

.ce-popover__items {
  padding-right: 5px;
}

.ce-popover-item {
  padding: 4px;
}

.ce-conversion-toolbar.ce-conversion-toolbar--showed {
  padding-left: 5px;
  padding-right: 5px;
}

.ce-conversion-tool {
  margin-bottom: 3px;
  border-radius: 3px;
}

.ce-conversion-tool:hover,
.ce-popover-item:hover {
  background-color: #ef233c !important;
  color: white;
}

.ce-conversion-tool:hover .ce-conversion-tool__icon,
.ce-popover-item:hover .ce-popover-item__icon {
  background-color: white !important;
  color: #ef233c;
}

.cdx-list__item {
  line-height: 3px;
}

.ce-conversion-tool.ce-conversion-tool--focused,
.selection-list-option-active,
.ce-popover-item--active {
  background-color: #ef233c !important;
  color: white !important;
}

.ce-conversion-tool.ce-conversion-tool--focused .ce-conversion-tool__icon,
.ce-popover-item--active .ce-popover-item__icon {
  background-color: white !important;
  color: #ef233c;
}

.ce-inline-toolbar__buttons {
  align-items: center;
  padding: 5px;
}

.ce-inline-toolbar__buttons button {
  border: 1px solid #ef233c;
  color: #ef233c;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  margin-left: 5px;
}

.ce-inline-tool.ce-inline-tool--font .button-wrapper-text {
  font-size: 12px;
  color: #ef233c;
  font-weight: 600;
}

.ce-inline-toolbar__buttons button:hover,
.ce-inline-toolbar__buttons button:hover .button-wrapper-text {
  background-color: #ef233c;
  color: white !important;
}

.ce-inline-toolbar__toggler-and-button-wrapper {
  border: 1px solid #ef233c;
  border-radius: 5px;
}

.ce-inline-toolbar__dropdown {
  border-right-color: #ef233c;
  background-color: #ef233c;
  color: white;
}

.ce-inline-toolbar__dropdown:hover {
  background-color: transparent;
  color: #ef233c;
}

.ce-inline-tool.ce-inline-tool--font .icon--toggler-down {
  display: none !important;
}

.ce-conversion-toolbar__label {
  color: #ef233c;
}

.ce-inline-tool.ce-inline-tool--font .selectionList {
  margin-top: 5px;
}

.ce-inline-tool.ce-inline-tool--font .selectionList .selection-list-wrapper {
  border: 1px solid #ef233c !important;
  border-radius: 4px !important;
}

.ce-inline-tool.ce-inline-tool--font
  .selectionList
  .selection-list-wrapper
  div.selection-list-option {
  color: #ef233c;
  font-size: 12px;
}

.ce-inline-tool.ce-inline-tool--font
  .selectionList
  .selection-list-wrapper
  div.selection-list-option:hover {
  background-color: #ef233c !important;
  color: white !important;
}

/* .portfolioImage :where(.css-dev-only-do-not-override-zggqhc).ant-image {
  position: relative;
  display: inline-block;
  width: 100% !important;
  height: 100% !important;
}
.portfolioImage :where(.css-dev-only-do-not-override-zggqhc).ant-image .details-delete {
  position: absolute !important; top: 20% !important; z-index: 99 !important; right: 5% !important;
} */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #002c6b;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.newScroll html {
  scrollbar-width: thin;
  scrollbar-color: #002c6b #f1f1f1;
}

/* / --------------------------------------sidebar styles------------------------------------------- // */
.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: var(--primary) !important;
}

.bg-custom-1 {
  background-color: #ffffff !important;
  transition: all 0.3s ease;
}

.bg-primary {
  background-color: var(--primary) !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #cefbd7 !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: rgb(255, 255, 255) !important;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: var(--primary);
}

.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
  border-color: var(--primary);
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #312e2e !important;
}

.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ),
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  background-color: var(--primary) !important;
  color: white;
}

.ant-menu-light {
  background-color: #ffffffcc !important;
  color: var(--primary);
}

.ant-btn-primary {
  background-color: var(--primary) !important;
}

.ant-ribbon {
  background-color: var(--primary) !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: var(--primary-two) !important;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: var(--primary-two) !important;
}

.ant-btn-default:not(:disabled):hover {
  color: white;
  border-color: var(--primary) !important;
}

.border-btn:not(:disabled):hover {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary) !important;
}

.text-editor .ql-container:hover,
.text-editor .ql-container:focus-within {
  border: 1px solid var(--primary) !important;
  border-radius: 0 0 10px 10px;
}

.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  stroke: var(--primary) !important;
}

.ql-toolbar.ql-snow .ql-picker-label:hover {
  color: var(--primary) !important;
}

.ql-snow.ql-toolbar button:hover > * {
  stroke: var(--primary) !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--primary) !important;
}

.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--primary);
}

.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: var(--hover);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: var(--primary);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: var(--primary);
}

.more-btn {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.more-btn:hover {
  border-color: transparent;
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--primary);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--accent);
}

.ant-btn-dangerous:hover {
  background-color: var(--danger-alt);
  border: none !important;
  color: white;
}

.ant-select-focused .ant-select-selector,
.ant-select-open.ant-select-show-search .ant-select-selector {
  border: 1px solid var(--primary) !important;
}

.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--primary) !important;
}

h5.input_img_text > span.text-info {
  color: var(--primary) !important;
}

.ant-spin {
  color: var(--primary);
}

.border-button:hover,
.edit-button:hover {
  color: var(--primary) !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--accent);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}

.ant-checkbox-checked:after {
  border-color: var(--primary) !important;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: var(--primary);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary) !important;
}

.ant-switch.ant-switch-checked {
  background-color: var(--primary);
}

.example-text-textarea > div > div > div:first-child {
  max-width: 100%;
}

.chat .chat-content .chat-content-body .msg.msg-sent .bubble .bubble-wrapper {
  background-color: var(--primary);
}

.ant-dropdown-menu-item:has(.chat-dropdown-list) {
  padding: 3px 15px !important;
}

.ant-dropdown-menu-item .chat-dropdown-list {
  font-size: 12px !important;
}

.ant-dropdown-menu-item:has(.chat-dropdown-list):hover {
  background-color: var(--accent) !important;
}

.ant-dropdown-menu-item:has(.chat-dropdown-list.remove):hover {
  background-color: var(--primary-two) !important;
  color: white;
}

/* --------------------------------table styling----------------------------------- */
/* --------------------------------table styling----------------------------------- */

/* .ant-table-nested-thead th{background-color: #72849a!important; color: #f0eaeaf0 !important;}  */
/* .subscription-table  */
.ant-table-thead th {
  background-color: #002c6b !important;
  color: #fff !important;
}

/* Change the color of the sort and filter icons */
.ant-table-thead th .ant-table-column-sorter-inner {
  color: #fff !important;
}

/* Change the color of the sort icon when sorted */
.ant-table-thead th.ant-table-column-sort {
  color: #fff !important;
}

/* Change the color of the filter icon */
.ant-table-filter-trigger {
  color: #fff !important;
}

/* Change the background color of selected rows */
.ant-table-tbody tr.ant-table-row-selected td {
  background-color: #e4e4e4 !important;
  /* Change to the color you want */
}

/* Change the text color of selected rows */
.ant-table-tbody tr.ant-table-row-selected td {
  color: #002c6b !important;
  /* Change to the color you want */
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-options-size-changer {
  color: #002c6b !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.deleteButton {
  background-color: #ef233c;
  color: #ffffff;
}

.deleteButton:hover {
  background-color: #ffffff;
}

.inActiveButton {
  border-color: #ef233c;
  color: #ef233c;
}

.activeButton {
  border-color: #002c6b;
  color: #002c6b;
}

.headerSaveButton {
  background-color: #f9bc04;
  color: #ffffff;
  border-color: #e3ab03;
  font-weight: bold;
  transition: color 0.3s;
}

.headerSaveButton:hover {
  color: #002c6b; /* Change text color on hover */
  border-color: #002c6b; /* Change border color on hover */
}

.headerSaveDisableButton {
  color: #002c6b;
  border-color: #002c6b;
  font-weight: bold;
  background-color: white;
}

/*///////////////////////////////////Binita/////////////////////////////////////////////*/

.table-width {
  width: 49%;
}
.admin-tabs {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: start;
}
.refresh-btn {
  display: block;
}
.form-width {
  width: 65%;
}
.img-form-width {
  width: 35%;
}
.width-50 {
  width: 50%;
}
.flex-col {
  flex-flow: column;
}

/* ********|| RESPONSIVE STARTS ||******** */

@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 990px) {
  .table-width {
    width: 100%;
  }
  .admin-tabs {
    justify-content: center;
  }
  .form-width {
    width: 100%;
  }
  .img-form-width {
    width: 100%;
  }
  .img-form-width .ant-form-item {
    margin-top: 20px;
  }
  .table-responsive .ant-rate {
    display: flex;
  }
  .appointment-table .ant-table-tbody .ant-table-row .ant-table-cell p {
    display: flex;
  }
  .appointment-table .ant-table-tbody .ant-table-row .ant-table-cell div {
    width: 220px;
  }
  /* .table-responsive .ant-table-tbody .ant-table-row .ant-table-cell div{width: 100px;} */
  .table-responsive
    .ant-table-wrapper
    .ant-table-thead
    .ant-table-cell
    .ant-table-column-sorters {
    width: 100px;
  }
  .img-form-width .ant-form-item-control-input-content .file_upload_sec {
    margin: 0 !important;
  }
}
@media screen and (max-width: 990px) and (orientation: landscape) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 767px) and (orientation: landscape) {
}
@media screen and (max-width: 600px) {
  .refresh-btn {
    display: none !important;
    justify-content: center;
    align-items: center;
  }
  .width-100 {
    width: 100% !important;
  }

  .table-responsive .ant-rate {
    display: flex;
  }
  .appointment-table .ant-table-tbody .ant-table-row .ant-table-cell p {
    display: flex;
  }
  .appointment-table .ant-table-tbody .ant-table-row .ant-table-cell div {
    width: 220px;
  }
  .table-responsive
    .ant-table-wrapper
    .ant-table-thead
    .ant-table-cell
    .ant-table-column-sorters {
    width: 100px;
  }
  .form-width {
    width: 100%;
  }
  .img-form-width {
    width: 100%;
  }
  .width-50 {
    width: 100%;
  }
  .wrap {
    flex-wrap: wrap;
    gap: 5px !important;
  }
  .margin-r0 .ant-btn {
    margin-right: 0px !important;
  }
  .border-right {
    border-right: 0;
  }
  /* .table-responsive .ant-table-content .ant-table-tbody .ant-table-cell div{width:250px;} */
  .dir-ltr .border-right {
    border: 0 solid #fff !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 360px) {
}
@media screen and (max-width: 320px) {
}
/* ********|| RESPONSIVE ENDS ||******** */

.custom-logout-button {
  color: red;
}

.custom-logout-button:hover {
  color: white;
  background-color: red; /* Optional: If you want to change the background color on hover */
}

.tox-statusbar__right-container{
  display: none!important;
}